import axios from "axios";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom"

export const API = `https://attendanceapi.ucmasindia.in/api`;
// export const API = `http://localhost:5001/api`;
const ScanResult = () => {


    // const API = `http://localhost:5001/api`;

    const navigate = useNavigate();
    const location = useLocation();
    const [attendance_entry_data, set_attendance_entry_data] = useState([]);
    const [showError, setShowError] = useState(false);
    useEffect(() => {

        
        mark_attendance();

    }, [location?.state])

    const [userData, setUserData] = useState(null);


    useEffect(() => {
        const d = sessionStorage.getItem("authToken");
        if (d)
            setUserData(JSON.parse(d));
        else
            navigate("/");
    }, [])

    const mark_attendance = () => {
        const d = location?.state;
        const data = {
            studentid: d.id,
            eventid: d.cid,
            barcode: d.code
        }
        if (data.studentid > 0)
            axios.post(`${API}/attendance_entry`, data).then(res => { console.log(res); set_attendance_entry_data(res.data.data) }).catch(ex => setShowError(true));
        else
            axios.post(`${API}/attendance_entry_manual`, data).then(res => {
                console.log(res);
                if (!res.data?.success) setShowError(true)
                else set_attendance_entry_data(res.data.data)
            }).catch(ex => setShowError(true));
    }

    useEffect(() => {
        console.log(attendance_entry_data)
    }, [attendance_entry_data])

    const handleLogout = () => {
        sessionStorage.removeItem('authToken');
        navigate('/');
      };
    
    return (
        <>
            <nav className="navbar navbar-expand-lg " style={{ backgroundColor: "#009AA8" }}>
                <div className="container-fluid">
                    <a className="navbar-brand" href="#"><img src={require("./favicon.png")} height={25} /> {userData?.name} </a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav">
                            <li className="nav-item">
                            <a className="nav-link active text-light" aria-current="page" href="/">Home</a>
                            <button className="btn btn-link text-decoration-none text-light" onClick={handleLogout}>Logout</button>
                            </li>

                        </ul>
                    </div>
                </div>
            </nav>
            <div className="container">

                <div className="row mt-4">
                    {attendance_entry_data?.length > 0 &&
                        <div className="col-md-12 col-sm-12">
                            {attendance_entry_data?.map(x => {

                                Object.entries(x).map(([key, value]) => console.log(key, value));
                                return (
                                    <table className="table table-sm table-success table-bordered">
                                        <tbody>
                                            <tr>
                                                <th scope="row">{"Student Name"}</th>
                                                <td>{x.studentName}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">{"Exam Code"}</th>
                                                <td>{x.examCode}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">{"Franchise Name"}</th>
                                                <td>{x.FRName}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">{"Category"}</th>
                                                <td>{x.category}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">{"ILC Category"}</th>
                                                <td>{x.ILCCategory}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">{"Event"}</th>
                                                <td>{x.eventType}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">{"Slot"}</th>
                                                <td>{x.timeSlot}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">{"Reporting Time"}</th>
                                                <td>{dayjs(x.reportingTime).format("HH:mm   A")}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">{"Exam Time"}</th>
                                                <td>{dayjs(x.examTime).format("HH:mm A")}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">{"Country"}</th>
                                                <td>{x.county}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">{"State"}</th>
                                                <td>{x.state}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">{"Attendance Count"}</th>
                                                <td>{x.attendancecount}</td>
                                            </tr>


                                            {/* {Object.entries(x).map(([key, value]) => {
                return (
                    <tr>
                        <td>{key}</td>
                        <td>{value}</td>
                    </tr>
                )
            })} */}
                                        </tbody>
                                    </table>
                                )
                            })}
                        </div>}
                    {showError &&
                        <div className="alert alert-danger">
                            <labe>{`No Records found`}</labe>
                        </div>
                    }
                    <div className="col-md-12 col-sm-12 d-grid">
                        <button className="btn btn-sm btn-warning btn-block" onClick={() => navigate("/scan")}>{`Back To Scanner`}</button>
                    </div>
                </div>

            </div>
        </>
    )

}

export default ScanResult;