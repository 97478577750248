
import { useNavigate } from "react-router-dom";
import QrReader from "./QRScanner";
import { useState } from "react";

const AttendanceScanner = ({ userData }) => {
    const navigate = useNavigate();
    console.log(userData)
    const [barcode, setbarcode] = useState('');

    const onSuccess = (data) => {
        const params = new URLSearchParams(data.split("?")[1]);
        navigate(`/scanresult`, { state: Object.fromEntries(params) });
        // console.log(data.split("?")[1]);
        // console.log(Object.fromEntries(params));
    };
    const onSearchClick = () => {

        const data = {
            id: 0,
            cid: userData?.event?.value,
            code: barcode
        }
        navigate(`/scanresult`, { state: data });
        // console.log(data.split("?")[1]);
        // console.log(Object.fromEntries(params));
    };

    return (
        <div className="container">
            <div className="row mt-2 gap-2">
                <div className="col-lg-4 col-md-4 col-md-12">
                    <h6>{userData?.event?.label}</h6>
                </div>
                <div className="col-lg-4 col-md-4 col-md-12">
                    <div class="input-group mb-3">
                        <span class="input-group-text" id="basic-addon1">Exam Code</span>
                        <input type="text" class="form-control" placeholder="Enter Student Exam Code" aria-label="Barcode" aria-describedby="basic-addon1" onChange={(e) => setbarcode(e.currentTarget.value)} />
                    </div>
                    <div className="d-grid">
                        <button className="btn btn-sm btn-success btn-block" onClick={() => onSearchClick()} disabled={barcode === "" ? true : false}>{`Search and Mark Present`}</button>
                    </div>
                    <div className="d-grid">
                       <label>Scan QR COde here </label>
                    </div>
                </div>
                <div className="col-lg-4 col-md-4 col-md-12">
                    <QrReader type="incentiveupdate" onSuccess={onSuccess} />
                </div>
            </div>

        </div>
    );
};

export default AttendanceScanner;