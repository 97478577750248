import { useNavigate } from "react-router-dom";
import { API } from "./ScanResult";
import { useEffect, useState } from "react";
import axios from "axios";
import Select from 'react-select';

const Login = () => {
    const [user, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [events, setEvents] = useState([]);
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();
        setError('');

        try {
            const response = await axios.post(`${API}/login`, {
                user,
                password,
            });

            // Save the token to localStorage
            const data = { ...response.data, event: selectedEvent }
            sessionStorage.setItem('authToken', JSON.stringify(data));
            navigate('/scan');
        } catch (err) {
            setError('Invalid credentials. Please try again.');
        }
    };


    useEffect(() => {
        getEvents();
    }, [])

    const getEvents = async () => {
        const response = await axios.get(`${API}/getEvents`);

        setEvents(response.data.data)
    }

    const handleChange = (option) => {
        setSelectedEvent(option)
    };

    return (
        <div className="">
            <nav className="navbar navbar-expand-lg " style={{ backgroundColor: "#009AA8" }}>
                <div className="container-fluid">
                    <a className="navbar-brand" href="#"><img src={require("./favicon.png")} height={25} /> UCMAS Login</a>
                    {/* <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <a className="nav-link active" aria-current="page" href="/">Home</a>
                            </li>

                        </ul>
                    </div> */}
                </div>
            </nav>

            <div style={{ height: "100vh", padding: "50px 20px" }}>
                <div className="border border-info rounded border-2 p-3">
                    <h2>Login</h2>
                    {error && <p style={{ color: 'red' }}>{error}</p>}
                    <form onSubmit={handleLogin}>
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-md-12">
                                <div class="form-group">
                                    <label for="formGroupExampleInput">Username</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="formGroupExampleInput"
                                        placeholder="Username"
                                        onChange={(e) => setUsername(e.target.value)}
                                        required />
                                </div>
                            </div>
                            <div className="col-lg-12 col-md-12 col-md-12">
                                <div class="form-group">
                                    <label for="formGroupExampleInput2">Password</label>
                                    <input
                                        type="password"
                                        class="form-control"
                                        id="formGroupExampleInput2"
                                        placeholder="Password"
                                        onChange={(e) => setPassword(e.target.value)}
                                        value={password}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="col-lg-12 col-md-12 col-md-12">
                                <div class="form-group">
                                    <label for="formGroupExampleInput2">Event</label>

                                    <Select
                                        value={selectedEvent}
                                        onChange={handleChange}
                                        options={events}
                                        placeholder="Select Event"
                                        required
                                    />

                                </div>
                            </div>
                            <div className="d-grid">
                                <button type="submit" className="btn btn-sm btn-block btn-success mt-2">Login</button>
                            </div>
                        </div>



                        {/* <div>
                            <label>Email</label>
                            <input
                                type="user"
                                value={user}
                                onChange={(e) => setUsername(e.target.value)}
                                required
                            />
                        </div>
                        <div>
                            <label>Password</label>
                            <input
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                        </div> */}

                    </form>
                </div>
            </div>


        </div>
    )

}

export default Login;