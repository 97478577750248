import React, { useEffect } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Login from "./Login";
import App from "./App";
import ScanResult from "./ScanResult";

const MainApp = () => {

    const isAuthenticated = !!sessionStorage.getItem('authToken');

    useEffect(() => {
        console.log(sessionStorage.getItem("authToken"))
    }, [])

    return (
        <React.StrictMode>
            <BrowserRouter>

                <Routes>
                    <Route path="/" element={<Login />} />
                    <Route path="/scan" element={<App />} />
                    <Route path="/scanresult" element={<ScanResult />} />
                </Routes>
            </BrowserRouter>
        </React.StrictMode>
    )
}

export default MainApp;