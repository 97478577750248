
import { useEffect, useState } from 'react';
import './App.css';
import AttendanceScanner from './AttendanceScanner';
import { useNavigate } from 'react-router-dom';

function App() {

  const [userData, setUserData] = useState(null);

  const navigate = useNavigate();
  useEffect(() => {
    const d = sessionStorage.getItem("authToken");
    if (d)
      setUserData(JSON.parse(d));
    else
      navigate("/");
  }, [])

  const handleLogout = () => {
    sessionStorage.removeItem('authToken');
    navigate('/');
  };

  return (
    <div className="App">
      <nav className="navbar navbar-expand-lg " style={{ backgroundColor: "#009AA8" }}>
        <div className="container-fluid">
          <a className="navbar-brand" href="#"><img src={require("./favicon.png")} height={25} /> {userData?.name} </a>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav">
              <li className="nav-item">
                <a className="nav-link active text-light" aria-current="page" href="/">Home</a>
                <button className="btn btn-link text-decoration-none text-light" onClick={handleLogout}>Logout</button>
              </li>

            </ul>
          </div>
        </div>
      </nav>
      <AttendanceScanner userData={userData} />
    </div>
  );
}

export default App;
